<template>
  <v-container fluid>
    <base-page-title>Request For Quote: Quote({{ $route.params.id }})</base-page-title>
    <quote-template
      editMode
      :activeTab="activeTab ? activeTab : localTab.active_tab"
    />
  </v-container>
</template>
<script>
export default {
  name: "UpdateQuote",
  props: ["id", "activeTab"],
  data() {
    return {
      localTab: {
        active_tab: null,
      },
    };
  },
  components: {
    QuoteTemplate: () => import("./QuoteTemplate.vue"),
  },
  mounted() {
    if (this.activeTab) return;
    if (localStorage.getItem("quote")) {
      this.localTab = JSON.parse(localStorage.getItem("quote"));
      localStorage.removeItem("quote");
    }
  },
};
</script>
